import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, query, where, orderBy } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';
import { datetimeFromTimestampToString } from '../librairies/Dates';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

const db = getFirestore(app);
ChartJS.register(ArcElement, Tooltip, Legend);

const DetailsSurvey = ({survey}) => {

  const [loader, setLoader] = useState(true);
  const [answers, setAnswers] = useState([]);

  //contexte de l'utilisateur connecté
  const user = useUser();

  useEffect(() => {
    const collectionRef = collection(db, user.user.userData.lastCoownershipLoaded.path+"/surveys/"+survey.id+"/answers");

    // Ajouter la condition where
    const q = query(collectionRef, where("status", "==", "accepted"), orderBy("dateSave", "desc"));

    const unsubscribe = onSnapshot(q, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((answer) => {
          arr.push(answer);
        });
        
        setAnswers(arr);
      }else{
        setAnswers([]);
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe answers");
      unsubscribe();
    }
  }, []); 

  const generateColors = (count) => {
    const predefinedColors = [
      'silver', 'navy', 'maroon', 'olive', 'coral',
      'turquoise', 'salmon', 'orchid', 'plum', 'lavender', 'tan', 'aqua', 'chocolate', 'khaki', 'peru', 
      'blue', 'red', 'yellow', 'orange', 'green', 'purple', 'cyan', 'magenta', 'lime', 'pink',
      'teal', 'brown', 'indigo', 'violet', 'gold'
    ];
  
    return Array.from({ length: count }, (_, i) => predefinedColors[i % predefinedColors.length]);
  };

  //const answers = survey.data().answers; // Récupération des réponses
  const labels = survey.data().responses.map((response) => response.text);
  const data = survey.data().responses.map((response) => response.nbAnswers ?? 0);

  // Génération des couleurs dynamiques
  const backgroundColors = generateColors(labels.length); // Ajout d'opacité
  const borderColors = generateColors(labels.length);

  const dataChartPie = {

    labels: labels,
    datasets: [
      {
        label: '# Nombre de réponses',
        data: data,
        backgroundColor: backgroundColors, 
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  }

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <p className='m-2 font-bold text-center'>Question: {survey.data().title}</p>

          <div className='my-4 text-center w-60 h-60 lg:w-96 lg:h-96 m-auto'>
            <Pie data={dataChartPie} />
          </div>

          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Prénom Nom
                </th>
                <th scope="col" className="px-6 py-3">
                  Téléphone
                </th>
                <th scope="col" className="px-6 py-3">
                  Numéro de logement
                </th>
                <th scope="col" className="px-6 py-3">
                  Réponse
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {answers.map((answer, key) => {
                return (
                  <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                    <td className="px-6 py-4">
                      {answer.data().firstname + " " + answer.data().lastname}
                    </td>
                    <td className="px-6 py-4">
                      {answer.data().phone}
                    </td>
                    <td className="px-6 py-4">
                      {answer.data().addressDetail}
                    </td>
                    <td className="px-6 py-4">
                      {answer.data().answer.map((element) => element).join(', ')}
                    </td>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {datetimeFromTimestampToString(answer.data().dateSave.seconds)}
                    </th>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default DetailsSurvey;
