import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, query, where, updateDoc, doc } from 'firebase/firestore'; 
import { isCoownership, useUser } from '../contexts/UserContext';
import DeleteModal from '../components/modals/DeleteModal';
import AddEvent from './AddEvent';
import moment from 'moment';
import { TEXT_SHARE_EVENT } from '../helpers/Text';
import ShareButton from '../components/buttons/ShareButton';
import { addEventButton, archiveEvent, changeDate, displayCalendar, displayEvents } from '../calendar/CalendarFunctions';
import UpdateEvent from './UpdateEvent';


const db = getFirestore(app);

const CalendarEvents = ({ selectedDate = new Date() }) => {

  const [events, setEvents] = useState([]); 
  const [loader, setLoader] = useState(true);
  const [showAddEvent, setShowAddEvent] = useState(false); 
  const [showUpdateEvent, setShowUpdateEvent] = useState(false); 
  const [showArchiveModal, setShowArchiveModal] = useState(false); 
  const [elementToArchive, setElementToArchive] = useState(null); 
  const [dateCalendar, setDateCalendar] = useState(new Date()); 
  const [dateBegining, setDateBegining] = useState(new Date()); 
  const [eventToUpdate, setEventToUpdate] = useState(null);
  const [dateEnd, setDateEnd] = useState(() => {
    const nextDay = new Date(dateBegining); // Clone `dateBegining`
    nextDay.setDate(nextDay.getDate() + 1); // Incrémente le jour de 1
    return nextDay;
  });

  const [eventsDate, setEventsDate] = useState([]);

  //contexte de l'utilisateur connecté
  const user = useUser();  

  useEffect(() => {
    if (selectedDate) {
      setDateCalendar(selectedDate);
      changeDate(selectedDate, setDateBegining, setDateEnd);
    }
  }, [selectedDate]);

  useEffect(() => {
    if(eventToUpdate !== null){
      setShowUpdateEvent(true); 
    }
  }, [eventToUpdate]);

  useEffect(() => {
    if(!showUpdateEvent){
      setEventToUpdate(null);
    }
  }, [showUpdateEvent]); 

  useEffect(() => {

    const queryPlanning = query(collection(db, user.user.userData.lastCoownershipLoaded.path+"/events"), 
      where('status', '==', 'accepted')); 
      
    const unsubPlanning = onSnapshot(queryPlanning, (snapshot) => {
      if(snapshot.docs.length > 0){
        setEventsDate([]); //on remet les dates à vide pour insérer toutes les dates
        for(let i=0; i < snapshot.docs.length; ++i){ //on boucle sur l'ensemble des events activés 
      
          // Convertir le timestamp en millisecondes (Firestore utilise des millisecondes)
          const eventDate = new Date(snapshot.docs[i].data().dateBegining.seconds * 1000);

          setEventsDate(dates => [...dates, moment(eventDate).format('YYYY-MM-DD')]);
        }
      }else{
        setEventsDate([]); //il n'y a pas d'events
      }
    
    }, error => { console.log(error) });
  
    return () => {
      console.log("unsubscribe dates event");
      unsubPlanning();
    }
  }, []); 

  useEffect(() => {
    
    const collectionRef = query(collection(db, user.user.userData.lastCoownershipLoaded.path+"/events"), 
      where('status', '==', 'accepted'),
      where('dateBegining', '>=', dateBegining), //'2024-10-16T00:00:00'
      where('dateBegining', '<', dateEnd)) //'2024-10-17T00:00:00'

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((event) => {
          let obj = {
            coownership: user.user.userData.lastCoownershipLoaded.path,
            id: event.id, 
            idCreator: event.data().idCreator.path,
            title: event.data().title, 
            description: event.data().description, 
            firstname: event.data().firstnameCreator,
            lastname: event.data().lastnameCreator,
            dateBegining: event.data().dateBegining.seconds,
            dateEnd: event.data().dateEnd.seconds,
            status: event.data().status,
            files: event.data().files,
            multipleDates: event.data()?.multipleDates ?? false, 
          }

          arr.push(obj);

        });
        
        setEvents(arr);
      }else{
        setEvents([]); 
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe events");
      unsubscribe();
    }
  }, [dateBegining]); 


  useEffect(() => {
    if(elementToArchive !== null){
      setShowArchiveModal(true); 
    }
  }, [elementToArchive]);

  useEffect(() => {
    if(showArchiveModal === false){
      setElementToArchive(null);
    }
  }, [showArchiveModal]);

  return (
    <div>      
      <div className='flex gap-4 justify-end'>
        {isCoownership(user.user) && (
          <ShareButton text={TEXT_SHARE_EVENT(user.user.userData.codeOwner)} designBox={false} />
        )}
        {addEventButton(dateBegining, setShowAddEvent)}
      </div>

      {displayCalendar(dateCalendar, setDateCalendar, setDateBegining, setDateEnd, eventsDate)}
      
      {displayEvents(user, loader, events, dateBegining, setElementToArchive, false, setEventToUpdate)}

      {showAddEvent ? (
        <AddEvent onClose={() => setShowAddEvent(false)} date={dateBegining}/>
      ) : null }

      {showUpdateEvent ? (
        <UpdateEvent onClose={() => setShowUpdateEvent(false)} eventId={eventToUpdate}/>
      ) : null }

      {showArchiveModal ? (
        <DeleteModal closeAction={() => setShowArchiveModal(false)} confirmAction={() => archiveEvent(elementToArchive, setShowArchiveModal)} type={"archive"}/>
      ) : null }
    </div>
  );
};

export default CalendarEvents;

