import { getFirestore, collection, addDoc, Timestamp, doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { useUser } from '../contexts/UserContext';
import Label from '../components/texts/Label';
import { datetimeFromTimestampToInput, datetimeFromTimestampToString, timeFromTimestampToString } from '../librairies/Dates';
import DateTimeInput from '../components/inputs/DateTimeInput';

const db = getFirestore(app);

function UpdateSurvey({onClose, data}) {

  //contexte de l'utilisateur connecté
  const user = useUser();
  const [responses, setResponses] = useState([{ text: "" }]);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
 const [expirationDate, setExpirationDate] = useState(datetimeFromTimestampToInput(data.data().dateEnd.seconds));

  console.log(data.id)
  console.log(data.data())
  const handleAddResponse = () => {
    setResponses([...responses, { text: "" }]);
  };

  const handleResponseChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index].text = value;
    setResponses(newResponses);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    try {
      let responseArray = data.data().responses; 

      responses.forEach(element => {
        responseArray.push(element)
      });

  
      const surveyRef = doc(db, `${user.user.userData.lastCoownershipLoaded.path}/surveys/${data.id}`);
      //todo a tester : voir si ça écrase pas les données 
      //par exemple si on ajoute un champs (nbResponse) avec un incrément pour compter le nombre de réponses séléctionné.
      //est-ce que ça fonctionnera ?
      await updateDoc(surveyRef, {
        responses: responseArray,
        dateEnd: Timestamp.fromDate(new Date(expirationDate)),
      });
      

      onClose();
    } catch (err) {
      console.log(err)
      setError("Erreur lors de l'enregistrement du sondage.");
    }

    setLoader(false);
  };

  useEffect(() => {
    console.log(expirationDate)
  }, [expirationDate])

  return (
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Modifier un sondage</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>

                <div className='border-b border-gray mt-0.5 mb-2'></div>

                <Label text={"Question : "+data.data().title} />

                <div className="mt-4">
                  <Label text={"Type de sondage : "+data.data().type} />
                </div>

                <div className="mt-4">
                  <Label text={"Options de réponse"} required={true} />
                  {data.data().responses.map((response, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2">
                      {/* ici on affiche les réponses qui ne sont pas modifiables */}
                      <Label text={"- "+response.text} />
                    </div>
                  ))}
                  {/* ici on permet d'ajouter de nouvelles réponses */}
                  {responses.map((response, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2">
                      <input type="text" value={response.text} onChange={(e) => handleResponseChange(index, e.target.value)} className="border p-2 rounded flex-grow" />
                    </div>
                  ))}
                  <button type="button" onClick={handleAddResponse} className="text-blue-500 text-sm">+ Ajouter une réponse</button>
                </div>
              </div>

              <div className="mt-4">
                <Label text={"Sondage destiné à : "+(data.data().coownerTarget ? "- Copropriétaires " : "") +(data.data().coownershipComiteeTarget ? "- CS " : "") +(data.data().tenantTarget ? "- Résidents" : "")} />
              </div>

              <div className="mt-4">
                <DateTimeInput id={"expirationDate"} label={"Date d'expiration"} value={expirationDate} onChange={setExpirationDate} />
              </div>
              <div className="mt-4">
                <Label text={"Date de fin du sondage : "+datetimeFromTimestampToString(data.data().dateEnd.seconds)} />
              </div>

              <div className="mt-4 text-center">
                <PrimaryButton type={"submit"} value={"Modifier"} loading={loader} />
              </div>

              {error !== "" ? (
                <p className='justify-center flex my-1 text-red-500'>{error}</p>
              ) : null}
            
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default UpdateSurvey;