import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, query, where, orderBy, updateDoc, doc } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { isCoownership, useUser } from '../contexts/UserContext';
import { datetimeFromTimestampToString } from '../librairies/Dates';
import PrimaryButton from '../components/buttons/PrimaryButton';
import AddSurvey from './AddSurvey';
import UpdateSurvey from './UpdateSurvey';
import DeleteModal from '../components/modals/DeleteModal';
import DetailsSurvey from './DetailsSurvey';
import ShareButton from '../components/buttons/ShareButton';
import { TEXT_SHARE_SURVEY } from '../helpers/Text';

const db = getFirestore(app);

const ListSurveys = () => {

  const [surveys, setSurveys] = useState([]); 
  const [loader, setLoader] = useState(true);
  const [showAddSurvey, setShowAddSurvey] = useState(false); 
  const [elementToUpdate, setElementToUpdate] = useState(null);
  const [showUpdateSurvey, setShowUpdateSurvey] = useState(false); 
  const [showArchiveModal, setShowArchiveModal] = useState(false); 
  const [elementToArchive, setElementToArchive] = useState(null); 
  const [selectedSurvey, setSelectedSurvey] = useState(null); // Stocke le sondage sélectionné

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = collection(db, user.user.userData.lastCoownershipLoaded.path+"/surveys");

    // Ajouter la condition where
    const q = query(collectionRef, where("status", "==", "accepted"), where("statusCreator", "!=", "owner"), orderBy("statusCreator"), orderBy("dateEnd", "desc"));
    //const q = query(collectionRef, where("status", "==", "accepted"), orderBy("dateEnd", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((survey) => {
          arr.push(survey);
        });
        
        setSurveys(arr);
      }else{
        setSurveys([]);
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe surveys");
      unsubscribe();
    }
  }, []); 

  useEffect(() => {
    if(elementToUpdate != null){
      setShowUpdateSurvey(true); 
    }
  }, [elementToUpdate]);

  useEffect(() => {
    if(showUpdateSurvey === false){
      setElementToUpdate(null);
    }
  }, [showUpdateSurvey]);

  useEffect(() => {
    if(elementToArchive != null){
      setShowArchiveModal(true); 
    }
  }, [elementToArchive]);

  useEffect(() => {
    if(showArchiveModal === false){
      setElementToArchive(null);
    }
  }, [showArchiveModal]);

  /**
   * On archive un sondage
   */
  const archiveSurvey = async () => {
    console.log(elementToArchive)
    if(elementToArchive !== null){
      const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path+"/surveys/"+elementToArchive);
      await updateDoc(docRef, {
        status: "archived"
      });

      setShowArchiveModal(false);
    }
  }

  /**
   * 
   * @param {*} survey 
   * @returns 
   */
  const isSurveyFInished = (survey) => {
    return (Math.floor(Date.now() / 1000) > survey.data().dateEnd.seconds);
  }

  if (selectedSurvey) {
    return (
      <div>
        <button onClick={() => setSelectedSurvey(null)} className="mb-4 p-2 bg-primary text-white rounded">
          ← Retour à la liste
        </button>
        <DetailsSurvey survey={selectedSurvey} />
      </div>
    );
  }


  return (
    <div>
      <div className='flex gap-4 justify-end'>
        {isCoownership(user.user) && (
          <ShareButton text={TEXT_SHARE_SURVEY(user.user.userData.codeOwner)} designBox={false} />
        )}
        
        <a href="#" onClick={() => setShowAddSurvey(true)} className="justify-end flex mb-4">
          <PrimaryButton type={"button"} value={"Ajouter un sondage"} loading={false} />
        </a>
      </div>

      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Titre
                </th>
                <th scope="col" className="px-6 py-3">
                  Nombre de réponses
                </th>
                <th scope="col" className="px-6 py-3">
                  Date de création
                </th>
                <th scope="col" className="px-6 py-3">
                  Date de fin
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {surveys.map((survey, key) => {
                return (
                  <tr key={key} 
                    onClick={() => setSelectedSurvey(survey)}
                    className={ isSurveyFInished(survey) ? "bg-gray-800 border-b cursor-pointer text-white" :
                      (key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700 dark:text-white text-gray-900 cursor-pointer" : "border-b bg-gray-50 dark:bg-gray-800 text-gray-900 dark:border-gray-700 dark:text-white cursor-pointer")}>
                    <td className="px-6 py-4">
                      {survey.data().title}
                    </td>
                    <td className="px-6 py-4">
                      {survey.data().nbAnswers}
                    </td>
                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                      {datetimeFromTimestampToString(survey.data().dateSave.seconds)}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                      {datetimeFromTimestampToString(survey.data().dateEnd.seconds)}
                    </th>
                    <td className="px-6 py-4">
                      <div className='flex gap-4'>
                        <a href="#" onClick={(e) => {e.stopPropagation(); setElementToUpdate(survey)}} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Modifier</a>
                        <a href="#" onClick={(e) => {e.stopPropagation(); setElementToArchive(survey.id)}} className="font-medium text-red-600 dark:text-red-500 hover:underline">Supprimer</a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {showAddSurvey ? (
        <AddSurvey onClose={() => setShowAddSurvey(false)} />
      ) : null }

      {showUpdateSurvey ? (
        <UpdateSurvey onClose={() => setShowUpdateSurvey(false)} data={elementToUpdate} />
      ) : null } 

      {showArchiveModal ? (
        <DeleteModal type='delete' closeAction={() => setShowArchiveModal(false)} confirmAction={() => archiveSurvey()}/>
      ) : null }
    </div>
  );
};

export default ListSurveys;
