import { getFirestore, collection, addDoc, Timestamp, doc } from 'firebase/firestore';
import React, { useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/inputs/Input';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { isCoownership, useUser } from '../contexts/UserContext';
import TextareaInput from '../components/inputs/TextareaInput';
import CheckboxInput from '../components/inputs/CheckboxInput';
import DateTimeInput from '../components/inputs/DateTimeInput';
import Label from '../components/texts/Label';
import FilesInput from '../components/inputs/FilesInput';
import { handleUpload } from '../librairies/FirebaseStorage';

const db = getFirestore(app);

function AddSurvey({onClose, date}) {

  //contexte de l'utilisateur connecté
  const user = useUser();
  const [title, setTitle] = useState("");
  const [responses, setResponses] = useState([{ text: "" }]);
  const [expirationDate, setExpirationDate] = useState("");
  const [surveyType, setSurveyType] = useState("survey");
  const [surveyMultiple, setSurveyMultiple] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [coownershipComiteeTarget, setCoownershipComiteeTarget] = useState(false); 
  const [coownerTarget, setCoownerTarget] = useState(false); 
  const [tenantTarget, setTenantTarget] = useState(false); 
  const [files, setFiles] = useState([]);

  const handleAddResponse = () => {
    setResponses([...responses, { text: ""}]);
  };

  const handleResponseChange = (index, value) => {
    const newResponses = [...responses];
    newResponses[index].text = value;
    setResponses(newResponses);
  };

  const handleDeleteResponse = (index) => {
    if (responses.length > 1) {
      setResponses(responses.filter((_, i) => i !== index));
    }
  };

  /**
   * Modification des fichiers
   * @param {*} e 
   */
  const handleFilesChange = (e) => {
    console.log("e.target.files",e.target.files);
    if (e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files); // Convertir FileList en tableau
      setFiles(prevFiles => [...prevFiles, ...selectedFiles]); // Ajouter au state
    }
  };

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title){
      setError("Le titre est requis.");
      return;
    } 
    if (!expirationDate){
      setError("La date d'expiration est requise.");
      return;
    } 
    if (responses.some(resp => !resp.text)) {
      setError("Toutes les réponses doivent être remplies.");
      return;
    }
    if (!coownerTarget && !coownershipComiteeTarget && !tenantTarget) {
      setError("Vous devez sélectionner au minimum une cible pour le sondage"); 
      return; 
    }

    setLoader(true);

    let uploadedUrls = [];
    if(files.length > 0){
      for (const file of files) {
        let url = await handleUpload("surveys/", file);
        uploadedUrls.push(url);
      }
    }

    try {
      const surveyRef = collection(db, `${user.user.userData.lastCoownershipLoaded.path}/surveys`);

      let type = surveyType === "survey" ? (surveyMultiple ? "multiple" :  "simple") : "creneau";

      await addDoc(surveyRef, {
        title,
        responses,
        dateEnd: Timestamp.fromDate(new Date(expirationDate)),
        coownerTarget,
        coownershipComiteeTarget,
        tenantTarget,
        type: type, //simple ; creneau ; multiple
        idCreator: doc(db, "users/"+user.user.user.uid),
        dateSave: Timestamp.now(),
        status: "accepted",
        createdBy: isCoownership(user.user) ? "le gestionnaire" : ("le prestataire "+user.user.userData.firstname+" "+user.user.userData.lastname),
        statusCreator: user.user.userData.status, //copropriété ou prestataire
        files:uploadedUrls
      });

      onClose();
    } catch (err) {
      console.log(err)
      setError("Erreur lors de l'enregistrement du sondage.");
    }

    setLoader(false);
  };

  return (
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Ajouter un sondage</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>

                <div className='border-b border-gray mt-0.5 mb-2'></div>

                <Input id={"title"} label={"Question"} type={"text"} name={"title"} value={title} onChange={(e) => setTitle(e.target.value)} />

                <div className="mt-4">
                  <div className="flex gap-4">
                    <label className="flex items-center">
                      <input type="radio" name="surveyType" value="survey" checked={surveyType === "survey"} onChange={() => setSurveyType("survey")} />
                      <span className="ml-2 text-sm">Sondage</span>
                    </label>

                    <label className="flex items-center">
                      <input type="radio" name="surveyType" value="appointment" checked={surveyType === "appointment"} onChange={() => setSurveyType("appointment")} />
                      <span className="ml-2 text-sm">Rendez-vous</span>
                    </label>
                  </div>
                </div>

                <div className="mt-4">
                  <Label text={"Options de réponse"} required={true} />
                  {responses.map((response, index) => (
                    <div key={index} className="flex items-center gap-2 mb-2">
                      <input type="text" value={response.text} onChange={(e) => handleResponseChange(index, e.target.value)} className="border p-2 rounded flex-grow" />
                      {responses.length > 1 && (
                        <div className='w-5 h-5 rounded-full bg-red-500 flex items-center justify-center'>
                          <button type="button" onClick={() => handleDeleteResponse(index)} className=" text-white text-3xl font-bold mb-1">
                            -
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                  <button type="button" onClick={handleAddResponse} className="text-blue-500 text-sm">+ Ajouter une réponse</button>
                  
                  {surveyType === "survey" && (
                    <div className='mt-2'>
                      <CheckboxInput  id={"surveyTypeMultiple"} name={"surveyTypeMultiple"} label={"Autoriser plusieurs réponses"} checked={surveyMultiple} onChange={() => setSurveyMultiple(!surveyMultiple)} />
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <Label text={"Sondage destiné à"} required={true} />
                <div className='flex justify-between'>
                  <CheckboxInput  id={"coownershipComiteeTarget"} name={"coownershipComiteeTarget"} label={"CS"} checked={coownershipComiteeTarget} onChange={() => setCoownershipComiteeTarget(!coownershipComiteeTarget)} />
                  <CheckboxInput  id={"coownerTarget"} name={"coownerTarget"} label={"Copropriétaires"} checked={coownerTarget} onChange={() => setCoownerTarget(!coownerTarget)} />
                  <CheckboxInput  id={"tenantTarget"} name={"tenantTarget"} label={"Résidents"} checked={tenantTarget} onChange={() => setTenantTarget(!tenantTarget)} />
                </div>
              </div>

              <div className="mt-4">
                <DateTimeInput id={"expirationDate"} label={"Date d'expiration"} value={expirationDate} onChange={setExpirationDate} />
              </div>

              <div className="mt-4">
                <FilesInput id={"affichage"} name={"affichage"} label={"Images ou PDF"} files={files} onChange={handleFilesChange} onRemoveFile={handleRemoveFile} fileAccepted={"image/*,application/pdf"} />
              </div>

              <div className="mt-4 text-center">
                <PrimaryButton type={"submit"} value={"Ajouter"} loading={loader} />
              </div>

              {error !== "" ? (
                <p className='justify-center flex my-1 text-red-500'>{error}</p>
              ) : null}
            
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default AddSurvey;