import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, doc, updateDoc } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';

const db = getFirestore(app);

const ListTenant = () => {

  const [tenants, setTenants] = useState([]); 
  const [loader, setLoader] = useState(true);

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = collection(db, user.user.userData.lastCoownershipLoaded.path+"/tenant");

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((tenant) => {
          let obj = {
            id: tenant.id, 
            firstname: tenant.data().firstname, 
            lastname: tenant.data().lastname, 
            email: tenant.data().email,
            phone: tenant.data().phone,
            addressDetail: tenant.data().addressDetail, 
            coownershipComitee: tenant.data()?.coownershipComitee, 
          }

          arr.push(obj);

        });

        setTenants(arr);
      }
      setLoader(false);
    });

    return () => {
      console.log("unsubscribe tenant");
      unsubscribe();
    }
  }, []); 


  /**
   * Permet de modifier les membres du conseil syndical
   * @param {*} userId 
   * @param {*} integrate 
   */
  const setCoownershipComitee = async (userId, integrate) => {
    const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path+"/tenant/"+userId);

    await updateDoc(docRef, {
      coownershipComitee: integrate
    });
  }



  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                    Prénom
                </th>
                <th scope="col" className="px-6 py-3">
                    Nom
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Téléphone
                </th>
                <th scope="col" className="px-6 py-3">
                    Appartement 
                </th>
                <th scope="col" className="px-6 py-3">
                    Membre autorisé
                </th>
                <th scope="col" className="px-6 py-3">
                    Action 
                </th>
              </tr>
            </thead>
            <tbody>
              {tenants.length > 0 ? 
                tenants.map((tenant, key) => {
                  return (
                    <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {tenant.firstname}
                      </th>
                      <td className="px-6 py-4">
                        {tenant.lastname}
                      </td>
                      <td className="px-6 py-4">
                        {tenant.email}
                      </td>
                      <td className="px-6 py-4">
                        {tenant.phone}
                      </td>
                      <td className="px-6 py-4">
                        {tenant.addressDetail}
                      </td>
                      <td className="px-6 py-4">
                        {tenant.coownershipComitee ? "Oui" : "Non"}
                      </td>
                      <td className="px-6 py-4 flex gap-4">
                        <a href="#" onClick={() => setCoownershipComitee(tenant.id, !tenant.coownershipComitee)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">{tenant.coownershipComitee ? "Retirer des membres autorisés" : "Intégrer aux membres autorisés"}</a>
                        <a href={"mailto:support@trustymo.com?subject=Demande de blocage du compte "+tenant.email} className="font-medium text-red-600 dark:text-red-500 hover:underline">Bloquer</a>
                        <a href={"mailto:support@trustymo.com?subject=Demande de suppression du compte "+tenant.email} className="font-medium text-red-600 dark:text-red-500 hover:underline">Supprimer</a>
                      </td>
                    </tr>
                  )
                }) 
              : null }
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ListTenant;
