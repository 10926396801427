import moment from "moment";
import 'moment/locale/fr';

/**
 * Permet de transformer un nombre de secondes en format date compréhensible
 * @param {*} timestamp 
 * @returns 
 */
export const dateFromTimestampToString = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.utcOffset('+0000').format("ll"); //L
};

export const dateFromTimestampToHtmlFormat = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.utcOffset('+0000').format("YYYY-MM-DD"); //L
};

export const datetimeFromTimestampToString = (timestamp) => {
  moment.locale('fr');
  const timestampGenerated = moment.unix(timestamp);

  return timestampGenerated.format("LLL"); //L
};

/**
 * Permet de transformer un nombre de secondes en format heure 
 * @param {*} timestamp 
 * @returns 
 */
 export const timeFromTimestampToString = (timestamp) => {
  const date = new Date(timestamp * 1000);
  
  // Obtenir l'heure en heure française
  return date.toLocaleTimeString("fr-FR", {
    timeZone: "Europe/Paris",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const datetimeFromTimestampToInput = (timestamp) => {
  
  // Créer un objet Date à partir du timestamp en millisecondes
  const date = new Date(timestamp * 1000);
  
  // Calculer le décalage horaire entre UTC et l'heure locale française
  // getTimezoneOffset() renvoie la différence en minutes, négativée
  const offset = -date.getTimezoneOffset();
  
  // Créer une nouvelle date ajustée avec le décalage pour Paris
  // En hiver : UTC+1 (60 minutes), en été : UTC+2 (120 minutes)
  // Nous ajustons dynamiquement selon la date
  const heureLocale = new Date(date.getTime() + offset * 60000);
  
  // Utiliser toISOString() qui donne le format ISO standard, puis le modifier
  // toISOString() donne "YYYY-MM-DDTHH:mm:ss.sssZ"
  // Nous en extrayons seulement "YYYY-MM-DDThh:mm"
  return heureLocale.toISOString().substring(0, 16);
}