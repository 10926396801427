import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, doc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';
import DeleteModal from '../components/modals/DeleteModal';

const db = getFirestore(app);

const ListUsersProvider = () => {

  const [usersProvider, setUsersProvider] = useState([]); 
  const [loader, setLoader] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [elementToDelete, setElementToDelete] = useState(null); 
  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = collection(db, user.user.userData.lastCoownershipLoaded.path+"/usersProvider");

    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((owner) => {
          let obj = {
            id: owner.id, 
            firstname: owner.data().firstname, 
            lastname: owner.data().lastname, 
            email: owner.data().email,
            phone: owner.data().phone,
          }

          arr.push(obj);

        });

        setUsersProvider(arr);
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe");
      unsubscribe();
    }
  }, []); 

  useEffect(() => {
    if(elementToDelete != null){
      setShowDeleteModal(true); 
    }
  }, [elementToDelete]);

  useEffect(() => {
    if(showDeleteModal === false){
      setElementToDelete(null);
    }
  }, [showDeleteModal]);

  /**
   * On supprime un prestataire
   */
  const deleteProvider = async () => {
    if(elementToDelete !== null){
      const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path+"/usersProvider/"+elementToDelete);
      await deleteDoc(docRef);

      setShowDeleteModal(false);
    }
  }

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                    Prénom
                </th>
                <th scope="col" className="px-6 py-3">
                    Nom
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Téléphone
                </th>
                <th scope="col" className="px-6 py-3">
                    Action
                </th>
              </tr>
            </thead>
            <tbody>
              {usersProvider.length > 0 ? 
                usersProvider.map((provider, key) => {
                  return (
                    <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {provider.firstname}
                      </th>
                      <td className="px-6 py-4">
                        {provider.lastname}
                      </td>
                      <td className="px-6 py-4">
                        {provider.email}
                      </td>
                      <td className="px-6 py-4">
                        {provider.phone}
                      </td>
                      <td className="px-6 py-4">
                        <div className='flex gap-4'>
                          <a href="#" onClick={() => setElementToDelete(provider.id)} className="font-medium text-red-600 dark:text-red-500 hover:underline">Supprimer</a>
                        </div>
                      </td>
                    </tr>
                  )
                })
              : null }
            </tbody>
          </table>
        </div>
      )}

      {showDeleteModal ? (
        <DeleteModal closeAction={() => setShowDeleteModal(false)} confirmAction={() => deleteProvider()}/>
      ) : null }
    </div>
  );
};

export default ListUsersProvider;
