import { collection, doc, getFirestore, addDoc, updateDoc, Timestamp, query, where, orderBy, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/inputs/Input';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { useUser } from '../contexts/UserContext';
import { dateFromTimestampToHtmlFormat } from '../librairies/Dates';
import { generateRandomCode } from '../librairies/Codes';
import { fetchData, fetchDocs } from '../librairies/Firestore';

const db = getFirestore(app);

function AddCoownership({onClose}) {

  //contexte de l'utilisateur connecté
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  
  const [codeOwner, setCodeOwner] = useState(generateRandomCode(6, true, false, true));
  const [codeTenant, setCodeTenant] = useState(generateRandomCode(6, true, false, true));
  const [codeProvider, setCodeProvider] = useState(generateRandomCode(6, true, false, true));

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [city, setCity] = useState("");
  const [registrationNumber, setRegistrationNumber] = useState(""); //numéro d'immatriculation

  const [existAccount, setExistAccount] = useState(false); 
  const [syndicAccount, setSyndicAccount] = useState([]); 
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [emailAccount, setEmailAccount] = useState("");
  const [password, setPassword] = useState(generateRandomCode());

  
  const [nameSyndic, setNameSyndic] = useState("");
  /*
  const [phoneSyndic, setPhoneSyndic] = useState("");
  const [mailSyndic, setMailSyndic] = useState(""); 
  */

  const [errors, setErrors] = useState({
    address: '',
    city: '',
    zip: '',
    name: '',
    emailAccount: '',
    nameSyndic: '',
  });


  const resetState = () => {
    setError("");
    setAddress("");
    setCity("");
    setZip("");
    setName("");
    setEmailAccount(""); 
    setPassword("");
    setCodeOwner("");
    setCodeTenant(""); 
    setCodeProvider("");
  }

  useEffect(() => {
    console.log("we load syndics accounts"); 
    getSyndicAccounts();
  }, []); 

  
  const updateCoownershipArray = async (newCoownership) => {
    const docRef = doc(db, 'users', selectedAccount);
    let user = await fetchData(docRef); 

    if(user !== null){
      console.log(user);

      let coownershipArray = user.coownershipId; 

      let newObj = {coownershipReference: doc(db, newCoownership)}; 

      coownershipArray.push(newObj); 

      //on envoie les données sur firestore 
      await updateDoc(docRef, {
        coownershipId: coownershipArray
      });
    }
  }

  const getSyndicAccounts = async () => {
    const collectionRef = query(collection(db, "users"), where("status", "==", "copropriete"), orderBy("email"));

    let data = await getDocs(collectionRef); 
    


    if(data !== null){
      console.log("data fetch");
      console.log(data);

      data.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
      });

      setSyndicAccount(data.docs); 
    }
  }

  const handleSubmit = async (e)  => {
    e.preventDefault();

    // Validation des champs
    const newErrors = {};

    if (!address) {
      newErrors.address = "L'adresse est requise.";
    }

    if (!city) {
      newErrors.city = "La ville est requise.";
    }

    if (!zip) {
      newErrors.zip = "Le code postal est requis.";
    }


    if (!name) {
      newErrors.name = "Le nom de la copropriété est requis.";
    }


    if (Object.keys(newErrors).length === 0) {
      console.log("Données du formulaire valides.");

      try{
        setLoader(true);
        
        //on envoie les données sur firestore 
        const coownershipCollection = collection(db, "coownership");

        //si le choix a été fait d'attribuer la copro à un compte syndic existant
        if(existAccount){
          console.log(selectedAccount);

          let emailSyndic = "";
          for(let i=0; i<syndicAccount.length; i++){
            if(syndicAccount[i].id === selectedAccount){
              emailSyndic = syndicAccount[i].data().email;
            }
          }

          if(selectedAccount !== null){
            await addDoc(coownershipCollection, {
              address: address,
              city:city,
              zip:zip, 
              name:name,
              nameSyndic: nameSyndic,
              email: emailSyndic,
              codeOwner: codeOwner,
              codeTenant: codeTenant,
              codeProvider: codeProvider,
              image: "https://firebasestorage.googleapis.com/v0/b/trustymo-72f96.appspot.com/o/coownershipPicture%2Fbernard-hermant-KqOLr8OiQLU-unsplash.jpg?alt=media&token=620baa2d-727f-4e07-b2f0-396dce037390", //image par défaut
              status: "activated",
              registrationNumber: registrationNumber,
              subscription: true,
            }).then(async (doc) => {
              console.log("le doc created")
              console.log(doc.path); 

              await updateCoownershipArray(doc.path); 

            }).catch((err) => {
              console.log(err)
              setError("Une erreur est survenue, impossible d'ajouter la copropriété créée à ce compte syndic");
              setLoader(false);

              return; 
            });
          }else {
            setError("Aucun compte syndic n'a été sélectionné");
            setLoader(false);

            return; 
          }
        }else{ //le choix a été fait de créer un nouveau compte pour le syndic 
          //on ajoute le nouveau document avec un nouveau compte pour le syndic
          await addDoc(coownershipCollection, {
            address: address,
            city:city,
            zip:zip, 
            name:name,
            nameSyndic: nameSyndic,
            codeOwner: codeOwner,
            codeTenant: codeTenant,
            codeProvider: codeProvider,
            image: "https://firebasestorage.googleapis.com/v0/b/trustymo-72f96.appspot.com/o/coownershipPicture%2Fbernard-hermant-KqOLr8OiQLU-unsplash.jpg?alt=media&token=620baa2d-727f-4e07-b2f0-396dce037390", //image par défaut
            status: "activated",
            email: emailAccount,
            emailAccount: emailAccount,
            passwordAccount: password,
            registrationNumber: registrationNumber,
            subscription: true,
          }).then(async (doc) => {
            console.log("le doc created")
            console.log(doc.path)
          }).catch((err) => {
            console.log(err)
            setError("Une erreur est survenue, impossible d'ajouter la copropriété");
            setLoader(false);
          });

          //le compte du syndic est créé grâce à une fonction (cloud functions)
        }



        setLoader(false);
        //on remet tous les states par défaut
        resetState(); 

        //on close la popup
        onClose(); 

      } catch(err){
        console.log(err)
        setError("Une erreur est survenue, impossible d'ajouter la copropriété");
        setLoader(false);
      }
    } else {
      // Afficher les erreurs
      setErrors(newErrors);
    }
  };


  return (
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Ajouter une copropriété</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>
                <div className='text-lg text-primary'>Les infos de la copropriété</div>
                <div className='border-b border-gray mt-0.5 mb-2'></div>
                <Input id={"name"} label={"Nom de la copropriété"} type={"text"} name={"name"} value={name} onChange={(event) => setName(event.target.value)} error={errors.name} />
                <Input id={"address"} label={"Adresse"} type={"text"} name={"address"} value={address} onChange={(event) => setAddress(event.target.value)} error={errors.address} />
                <Input id={"zip"} label={"Code Postal"} type={"text"} name={"zip"} value={zip} onChange={(event) => setZip(event.target.value)} error={errors.zip} />
                <Input id={"city"} label={"Ville"} type={"text"} name={"city"} value={city} onChange={(event) => setCity(event.target.value)} error={errors.city} />
                <Input id={"registrationNumber"} label={"Numéro d'immatriculation"} type={"text"} name={"registrationNumber"} value={setRegistrationNumber} onChange={(event) => setRegistrationNumber(event.target.value)} required={false}/>
              </div>

              <div className='mb-6'>
                <div className='text-lg text-primary'>Les infos du gestionnaire</div>
                <div className='border-b border-gray mt-0.5 mb-2'></div>
                <Input id={"nameSyndic"} label={"Nom du syndic"} type={"text"} name={"nameSyndic"} value={nameSyndic} onChange={(event) => setNameSyndic(event.target.value)} error={errors.nameSyndic} required={false} />
                {/*<Input id={"phoneSyndic"} label={"Téléphone du syndic"} type={"text"} name={"phoneSyndic"} value={address} onChange={(event) => setAddress(event.target.value)} error={errors.address} />
                <Input id={"emailSyndic"} label={"Email du syndic"} type={"email"} name={"emailSyndic"} value={email} onChange={(event) => setEmail(event.target.value)} error={errors.email} />*/}
              </div>

              <div className='mb-6'>
                <div className='text-lg text-primary'>Les infos du compte</div>
                <div className='text-sm'>informations de compte permettant de se connecter à l'interface web reservée aux copropriétés</div>
                <div className='border-b border-gray mt-0.5 mb-2'></div>


                <div className='flex gap-4 my-4'>
                  <input type="radio" value={false} name="gender" checked={!existAccount} onChange={() => setExistAccount(false)}/> Nouveau compte
                  <input type="radio" value={true} name="gender" checked={existAccount} onChange={() => setExistAccount(true)}/> Compte existant
                </div>

                {existAccount ? (
                  <div>
                    <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Selectionnez un compte</label>
                    <select id="account" onChange={(e) => setSelectedAccount(e.target.value)} 
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Sélectionnez un compte Syndic</option>
                      {syndicAccount.length > 0 ? (
                        syndicAccount.map((account) => {
                          return (
                            <option value={account.id}>{account.data().email}</option>
                          )
                        })
                      ) : null }
                    </select>
                  </div>
                ) : (
                  <div>
                    <Input id={"emailAccount"} label={"Email"} type={"email"} name={"emailAccount"} value={emailAccount} onChange={(event) => setEmailAccount(event.target.value)} error={errors.emailAccount} />
                    <div className='text-sm text-secondary'>Le compte sera créé avec le mot de passe ci-dessous. Veuillez le copier et le transmettre à la personne qui gèrera la copropriété. Le mot de passe ne sera pas réaffiché</div>

                    <div className='text-lg text-primary font-bold my-2 text-center'>{password}</div>
                  </div>
                )}



              </div>

              <div className="text-center">
                <PrimaryButton type={"submit"} value={"Ajouter"} loading={loader} />
              </div>

              {error !== "" ? (
                <p className='justify-center flex my-1 text-red-500'>{error}</p>
              ) : null}
              
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default AddCoownership;