import { getFirestore, doc, updateDoc, Timestamp, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/inputs/Input';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { useUser } from '../contexts/UserContext';
import { handleUpload } from '../librairies/FirebaseStorage';
import TextareaInput from '../components/inputs/TextareaInput';
import TimeInput from '../components/inputs/TimeInput';
import FilesInput from '../components/inputs/FilesInput';
import CheckboxInput from '../components/inputs/CheckboxInput';
import DateTimeInput from '../components/inputs/DateTimeInput';

const db = getFirestore(app);

function UpdateEvent({ onClose, eventId }) {
  //contexte de l'utilisateur connecté
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [existingFiles, setExistingFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [eventDate, setEventDate] = useState(null);

  const [timeBegining, setTimeBegining] = useState("09:00"); 
  const [timeEnd, setTimeEnd] = useState("17:00");

  const [dateTimeBegining, setDateTimeBegining] = useState(null); 
  const [dateTimeEnd, setDateTimeEnd] = useState("");

  const [multipleDates, setMultipleDates] = useState(false);
  const [eventPath, setEventPath] = useState("");

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    file: '',
    timeBegining: '',
    timeEnd: '',
    dateTimeBegining: '',
    dateTimeEnd: ''
  });

  // Charger les données de l'événement existant
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const docCoownership = user.user.userData.lastCoownershipLoaded.path;
        const eventDocPath = `${docCoownership}/events/${eventId}`;
        setEventPath(eventDocPath);
        
        const eventDoc = await getDoc(doc(db, eventDocPath));
        
        if (eventDoc.exists()) {
          const eventData = eventDoc.data();
          
          // Remplir les données du formulaire avec les données existantes
          setTitle(eventData.title || "");
          setDescription(eventData.description || "");
          
          // Gérer les dates et heures
          if (eventData.dateBegining) {
            const beginDate = eventData.dateBegining.toDate();
            setEventDate(beginDate);
            setDateTimeBegining(beginDate);
            setTimeBegining(`${beginDate.getHours().toString().padStart(2, '0')}:${beginDate.getMinutes().toString().padStart(2, '0')}`);
          }
          
          setMultipleDates(eventData.multipleDates || false);
          
          if (eventData.dateEnd) {
            const endDate = eventData.dateEnd.toDate();
            
            if (eventData.multipleDates) {
              // Pour un événement sur plusieurs jours, on initialise la date ET l'heure de fin
              // Format ISO pour input datetime-local: YYYY-MM-DDThh:mm
              const year = endDate.getFullYear();
              const month = String(endDate.getMonth() + 1).padStart(2, '0');
              const day = String(endDate.getDate()).padStart(2, '0');
              const hours = String(endDate.getHours()).padStart(2, '0');
              const minutes = String(endDate.getMinutes()).padStart(2, '0');
              
              const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
              setDateTimeEnd(formattedDate);
            } else {
              // Pour un événement sur un seul jour, on initialise juste l'heure de fin
              setTimeEnd(`${endDate.getHours().toString().padStart(2, '0')}:${endDate.getMinutes().toString().padStart(2, '0')}`);
            }
          }
          
          // Charger les fichiers existants
          if (eventData.files && eventData.files.length > 0) {
            setExistingFiles(eventData.files);
          }
        } else {
          setError("Événement introuvable");
        }
        
        setLoading(false);
      } catch (err) {
        console.error("Erreur lors du chargement de l'événement:", err);
        setError("Une erreur est survenue lors du chargement de l'événement");
        setLoading(false);
      }
    };

    if (eventId) {
      fetchEventData();
    }
  }, [eventId, user.user]);

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const handleRemoveExistingFile = (index) => {
    const fileToRemove = existingFiles[index];
    setFilesToDelete(prev => [...prev, fileToRemove]);
    setExistingFiles(prev => prev.filter((_, i) => i !== index));
  };

  // Fonction pour ajouter l'heure sélectionnée à la date et obtenir un timestamp
  const combineDateAndTime = (time) => {
    if (!eventDate) return null;
    
    const [hours, minutes] = time.split(':');
  
    // Création de la date en heure française
    const combinedDate = new Date(eventDate);
    combinedDate.setHours(hours);
    combinedDate.setMinutes(minutes);
    combinedDate.setSeconds(0);
    combinedDate.setMilliseconds(0);
  
    // Convertir la date en heure française en utilisant le fuseau Europe/Paris
    const combinedDateInFrance = new Date(
      combinedDate.toLocaleString("en-US", { timeZone: "Europe/Paris" })
    );
  
    return Timestamp.fromDate(combinedDateInFrance); // Conversion en Firestore Timestamp
  };

  /**
   * Mise à jour du document événement
   */
  const updateDocument = async (uploadedUrls) => {
    if (!eventPath) {
      return setError("Chemin d'événement invalide");
    }

    try {
      // Combiner les fichiers existants avec les nouveaux, en excluant ceux à supprimer
      const updatedFiles = [
        ...existingFiles, 
        ...(uploadedUrls || [])
      ];
      
                await updateDoc(doc(db, eventPath), {
        title: title,
        description: description,
        dateUpdate: Timestamp.fromDate(new Date()),
        dateBegining: combineDateAndTime(timeBegining),
        dateEnd: multipleDates ? Timestamp.fromDate(new Date(dateTimeEnd)) : combineDateAndTime(timeEnd),
        multipleDates: multipleDates,
        files: updatedFiles,
        // Ne pas mettre à jour firstnameCreator, lastnameCreator, idCreator pour préserver l'historique
      });

      onClose();
    } catch (err) {
      console.error("Erreur lors de la mise à jour:", err);
      setError("Une erreur est survenue, impossible de modifier l'événement");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation des champs
    const newErrors = {};

    if (!title) {
      newErrors.title = "Le titre est requis.";
    }

    if (!description) {
      newErrors.description = "La description est requise.";
    }

    if (!timeBegining) {
      newErrors.timeBegining = "L'heure de début est requise.";
    }

    if (!timeEnd && !multipleDates) {
      newErrors.timeEnd = "L'heure de fin est requise.";
    }

    if (multipleDates && !dateTimeEnd) {
      newErrors.dateTimeEnd = "La date de fin est requise.";
    }

    if (Object.keys(newErrors).length === 0) {
      console.log("Données du formulaire valides.");

      try {
        setLoader(true);
        
        let uploadedUrls = [];

        if (files.length > 0) {
          for (const file of files) {
            let url = await handleUpload("events/", file);
            const typeMedia = (file.type === "application/pdf") ? "pdf" : "image";
            let obj = {url: url, typeMedia: typeMedia};
            uploadedUrls.push(obj);
          }
        }
        
        await updateDocument(uploadedUrls);
        
        setLoader(false);
        onClose(); 

      } catch (err) {
        console.error(err);
        setError("Une erreur est survenue, impossible de modifier l'événement");
        setLoader(false);
      }
    } else {
      // Afficher les erreurs
      setErrors(newErrors);
    }
  };

  /**
   * Modification des fichiers
   */
  const handleFilesChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files); // Convertir FileList en tableau
      setFiles(prevFiles => [...prevFiles, ...selectedFiles]); // Ajouter au state
    }
  };

  if (loading) {
    return (
      <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
        <div className="relative w-full max-w-md max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-8">
            <div className="text-center">
              <p>Chargement des données...</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Modifier l'événement</h3>

            <form onSubmit={handleSubmit}>
              <div className='mb-6'>
                <div className='border-b border-gray mt-0.5 mb-2'></div>
                <div className='mt-4'>
                  <CheckboxInput 
                    id={"multipleDates"} 
                    label={"Événement sur plusieurs jours"} 
                    name={"multipleDates"} 
                    checked={multipleDates} 
                    onChange={() => setMultipleDates(!multipleDates)}
                  />
                </div>

                <div className='flex justify-between'>
                  <TimeInput 
                    id={"timeBegining"} 
                    label={"heure de début"} 
                    value={timeBegining} 
                    onChange={(time) => setTimeBegining(time)} 
                    error={errors.timeBegining}
                  />
                  {!multipleDates && (
                    <TimeInput 
                      id={"timeEnd"} 
                      label={"heure de fin"} 
                      value={timeEnd} 
                      onChange={(time) => setTimeEnd(time)} 
                      error={errors.timeEnd}
                    />
                  )}
                </div>
                
                {multipleDates && (
                  <div className=''>
                    <DateTimeInput 
                      id={"dateTimeEnd"} 
                      label={"Date de fin"} 
                      value={dateTimeEnd}
                      onChange={setDateTimeEnd} 
                      error={errors.dateTimeEnd}
                    />
                  </div>
                )}

                <Input 
                  id={"title"} 
                  label={"Titre"} 
                  type={"text"} 
                  name={"title"} 
                  value={title} 
                  onChange={(event) => setTitle(event.target.value)} 
                  error={errors.title} 
                />
                
                <TextareaInput 
                  id={"description"} 
                  label={"Description"} 
                  name={"description"} 
                  value={description} 
                  onChange={(event) => setDescription(event.target.value)} 
                  error={errors.description} 
                />
                
                {/* Affichage des fichiers existants */}
                {existingFiles.length > 0 && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Fichiers existants
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {existingFiles.map((file, index) => (
                        <div key={index} className="relative border rounded p-2">
                          <span>{file.typeMedia === 'pdf' ? 'PDF' : 'Image'}</span>
                          <button 
                            type="button" 
                            onClick={() => handleRemoveExistingFile(index)}
                            className="ml-2 text-red-500"
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                
                <FilesInput 
                  id={"affichage"} 
                  name={"affichage"} 
                  label={"Ajouter des images ou PDF"} 
                  files={files} 
                  onChange={handleFilesChange} 
                  onRemoveFile={handleRemoveFile} 
                  error={errors.file} 
                  fileAccepted={"image/*,application/pdf"} 
                />
              </div>

              <div className="text-center">
                <PrimaryButton type={"submit"} value={"Modifier"} loading={loader} />
              </div>

              {error !== "" ? (
                <p className='justify-center flex my-1 text-red-500'>{error}</p>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </div> 
  );
}

export default UpdateEvent;