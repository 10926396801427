import { collection, getFirestore, doc, addDoc, Timestamp } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/inputs/Input';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { isProvider, useUser } from '../contexts/UserContext';
import FileInput from '../components/inputs/FileInput';
import { handleUpload } from '../librairies/FirebaseStorage';
import TextareaInput from '../components/inputs/TextareaInput';
import TimeInput from '../components/inputs/TimeInput';
import { dateFromTimestampToString } from '../librairies/Dates';
import FilesInput from '../components/inputs/FilesInput';
import CheckboxInput from '../components/inputs/CheckboxInput';
import DateTimeInput from '../components/inputs/DateTimeInput';

const db = getFirestore(app);

function AddEvent({onClose, date, listCoownership=[]}) {

  //contexte de l'utilisateur connecté
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [filename, setFilename] = useState(null);
  const [files, setFiles] = useState([]);

  const [timeBegining, setTimeBegining] = useState("09:00"); 
  const [timeEnd, setTimeEnd] = useState("17:00");

  const [dateTimeBegining, setDateTimeBegining] = useState(new Date(date)); 
  const [dateTimeEnd, setDateTimeEnd] = useState("");

  const [multipleDates, setMultipleDates] = useState(false);

  const [coownership, setCoownership] = useState("");
  const [filteredCoownerships, setFilteredCoownerships] = useState("");
  const [searchTerm, setSearchTerm] = useState('');

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    file: '',
    timeBegining: '',
    timeEnd: '',
    dateTimeBegining,
    dateTimeEnd, 
    coownership: ''
  });

  const resetState = () => {
    setError("");
    setTitle("");
    setDescription("");
    setFiles([]);
    setCoownership("");
    setSearchTerm("");
  }

  const handleRemoveFile = (index) => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const coownershipsArray = listCoownership.filter((value) =>
      value.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredCoownerships(coownershipsArray);
  }, [searchTerm]); 

  /**
   * On crée le document billboard
   * @param {*} fileUrl 
   */
  const createDoc = async (uploadedUrls) => {
    //on envoie les données sur firestore 
    let docCoownership = ""; 
    if(listCoownership.length > 0){
      if(coownership === ""){
        return setError("Vous devez sélectionner une copropriété");
      }
      docCoownership = "coownership/"+coownership; 
    }else{
      docCoownership = user.user.userData.lastCoownershipLoaded.path;
    }
    const eventsCollection = collection(db, docCoownership+"/events");

    try{
      
      await addDoc(eventsCollection, {
        title: title,
        description:description,
        dateSave: Timestamp.fromDate(new Date()), 
        dateBegining: combineDateAndTime(timeBegining),
        dateEnd: multipleDates ? Timestamp.fromDate(new Date(dateTimeEnd)) : combineDateAndTime(timeEnd),
        multipleDates: multipleDates,
        firstnameCreator: isProvider(user.user) ? "le prestataire "+user.user.userData.firstname : "le gestionnaire", 
        lastnameCreator: isProvider(user.user) ? user.user.userData.lastname : "", 
        status: "accepted",
        idCreator: doc(db, 'users/' + user.user.user.uid),
        files: uploadedUrls,
      });

      if(listCoownership.length>0){
        window.location.reload();
      }
    } catch(err){
      setError("Une erreur est survenue, impossible d'ajouter l'événement");
    }
  }

  // Fonction pour ajouter l'heure sélectionnée à la date et obtenir un timestamp
  const combineDateAndTime = (time) => {
    const [hours, minutes] = time.split(':');
  
    // Création de la date en heure française
    const combinedDate = new Date(date);
    combinedDate.setHours(hours);
    combinedDate.setMinutes(minutes);
    combinedDate.setSeconds(0);
    combinedDate.setMilliseconds(0);
  
    // Convertir la date en heure française en utilisant le fuseau Europe/Paris
    const combinedDateInFrance = new Date(
      combinedDate.toLocaleString("en-US", { timeZone: "Europe/Paris" })
    );
  
    return Timestamp.fromDate(combinedDateInFrance); // Conversion en Firestore Timestamp
  };

  const handleSubmit = async (e)  => {
    e.preventDefault();
    
    // Validation des champs
    const newErrors = {};

    if (!title) {
      newErrors.title = "Le titre est requis.";
    }

    if (!description) {
      newErrors.description = "La description est requise.";
    }

    if (!timeBegining) {
      newErrors.timeBegining = "L'heure de début est requise.";
    }

    if (!timeEnd) {
      newErrors.timeEnd = "L'heure de fin est requise.";
    }

    if(listCoownership.length > 0 && coownership === ""){
      newErrors.coownership = "Vous devez sélectionner une copropriété.";      
    }


    if (Object.keys(newErrors).length === 0) {
      console.log("Données du formulaire valides.");

      try{
        setLoader(true);
        
        if(files.length > 0){

          let uploadedUrls = [];

          for (const file of files) {
    
            let url = await handleUpload("events/", file);

            const typeMedia = (file.type == "application/pdf") ? "pdf" : "image";
    
            let obj = {url:url, typeMedia:typeMedia};
            
            uploadedUrls.push(obj);
          }
          createDoc(uploadedUrls);
        }else{
          createDoc(null); 
        }

        setLoader(false);
        //on remet tous les states par défaut
        resetState(); 

        //on close la popup
        onClose(); 

      } catch(err){
        console.log(err)
        setError("Une erreur est survenue, impossible d'ajouter l'événement");
        setLoader(false);
      }
    } else {
      // Afficher les erreurs
      setErrors(newErrors);
    }
  };

  
  /**
   * Modification des fichiers
   * @param {*} e 
   */
  const handleFilesChange = (e) => {
    if (e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files); // Convertir FileList en tableau
      setFiles(prevFiles => [...prevFiles, ...selectedFiles]); // Ajouter au state
    }
  };


  return (
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Ajouter un événement le {dateFromTimestampToString(Math.floor(date.getTime()/1000))}</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>

                {listCoownership.length > 0 ? (
                  <div className='my-6'>
                    <input
                      type="text"
                      placeholder="Rechercher une copropriété"
                      value={searchTerm}
                      onChange={(event) => setSearchTerm(event.target.value)}
                      className=" p-2 border rounded-xl w-full focus:border-primary"
                    />
                    <select id="account" onChange={(e) => setCoownership(e.target.value)} 
                     className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Sélectionnez une copropriété</option>
                      {filteredCoownerships.length > 0 ? (
                        filteredCoownerships.map((value) => {
                          return (
                            <option value={value.id}>{value.name}</option>
                          )
                        })
                      ) : null }
                    </select>

                    {errors.coownership && (
                      <p className="text-red-500 text-sm mt-1">{errors.coownership}</p>
                    )}
                  </div>
                ) : null }

                <div className='border-b border-gray mt-0.5 mb-2'></div>
                  <div className='mt-4'>
                    <CheckboxInput id={"multipleDates"} label={"Événement sur plusieurs jours"} name={"multipleDates"} checked={multipleDates} onChange={() => setMultipleDates(!multipleDates)}/>
                  </div>

                  
                    <div className='flex justify-between'>
                      <TimeInput id={"timeBegining"} label={"heure de début"} value={timeBegining} onChange={(time) => setTimeBegining(time)} error={errors.timeBegining}/>
                      {!multipleDates && (
                        <TimeInput id={"timeEnd"} label={"heure de fin"} value={timeEnd} onChange={(time) => setTimeEnd(time)} error={errors.timeEnd}/>
                      )}
                    </div>
                 
                  
                  {multipleDates && (
                    <div className=''>
                      <DateTimeInput id={"dateTimeEnd"} label={"Date de fin"} value={dateTimeEnd} onChange={setDateTimeEnd} error={errors.dateTimeEnd}/>
                    </div>
                  )}

                  <Input id={"title"} label={"Titre"} type={"text"} name={"title"} value={title} onChange={(event) => setTitle(event.target.value)} error={errors.title} />
                  <TextareaInput id={"description"} label={"Description"} name={"description"} value={description} onChange={(event) => setDescription(event.target.value)} error={errors.description} />
                  <FilesInput id={"affichage"} name={"affichage"} label={"Images ou PDF"} files={files} onChange={handleFilesChange} onRemoveFile={handleRemoveFile} error={errors.file} fileAccepted={"image/*,application/pdf"} />
                </div>

                <div className="text-center">
                  <PrimaryButton type={"submit"} value={"Ajouter"} loading={loader} />
                </div>

                {error !== "" ? (
                  <p className='justify-center flex my-1 text-red-500'>{error}</p>
                ) : null}
              
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default AddEvent;