import Loader from "../components/Loader";
import PrimaryButton from "../components/buttons/PrimaryButton";
import { dateFromTimestampToString, timeFromTimestampToString } from "../librairies/Dates";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import '../assets/css/Calendar.css'
import moment from 'moment';
import styled from "styled-components";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import { isCoownership, isProvider } from "../contexts/UserContext";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { app } from "../firebase";
const db = getFirestore(app);

const tileStyles = {
  backgroundColor: '#E49680',
  color: 'white',
  borderRadius: '50%',
  height: '32px',
  width:'32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const Wrapper = styled.div`
  .controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .btn-arrow {
      border: none;
      background: none;
      padding: 11px 20px;
    }

    .reverse-arrow {
      transform: rotateY(180deg);
    }

    .dot-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel__dot {
        width: 8px;
        height: 8px;
        border: none;
        border-radius: 50%;
        margin: 0 4px;
        padding: 0;
        background-color: #c3c4ca;
      }

      /* This class is found in DotGroup from pure-react-carousel */
      /* We need to override it to add our styles */
      .carousel__dot--selected {
        width: 16px;
        height: 8px;
        border-radius: 10px;
        background-color: #E49680;
        transition: background 0.4s ease;
      }
    }
  }
`;

function getResponsiveRatio() {
  // Définition des largeurs d'écran pour les breakpoints
  const MOBILE_MAX_WIDTH = 767;  // Largeur max pour mobile en pixels
  const TABLET_MAX_WIDTH = 1024; // Largeur max pour tablette en pixels

  // Définition des ratios (30:50:100)
  const DESKTOP_RATIO = 30;   // 30% pour mobile
  const TABLET_RATIO = 50;   // 50% pour tablette
  const MOBILE_RATIO = 100;  // 100% pour PC

  // Récupération de la largeur actuelle de la fenêtre
  const currentWidth = window.innerWidth;
  
  // Détermination du ratio en fonction de la taille d'écran
  if (currentWidth <= MOBILE_MAX_WIDTH) {
    return MOBILE_RATIO;
  } else if (currentWidth <= TABLET_MAX_WIDTH) {
    return TABLET_RATIO;
  } else {
    return DESKTOP_RATIO;
  }
}

export const isSpecialDate = (eventsDate, date) => {
  // Convertit la date en chaîne AAAA-MM-JJ
  const dateString = moment(date).format('YYYY-MM-DD');
  return eventsDate.includes(dateString);
};

export const changeDate = (value, setDateBegining, setDateEnd) => {
  // Crée un nouvel objet Date pour la date sélectionnée
  const selected = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 2, 0, 0);

  // Crée une nouvelle date pour le jour suivant
  const nextDate = new Date(selected);
  nextDate.setDate(selected.getDate() + 1);

  setDateBegining(selected); 
  setDateEnd(nextDate); 
}

export const addEventButton = (dateBegining, setShowAddEvent) => {
  return(
    <a href="#" onClick={() => setShowAddEvent(true)} className="justify-end flex mb-4">
      <PrimaryButton type={"button"} value={"Ajouter un événement le "+dateFromTimestampToString(Math.floor(dateBegining.getTime()/1000))} loading={false} />
    </a>
  );
}

export const displayCalendar = (dateCalendar, setDateCalendar, setDateBegining, setDateEnd, eventsDate) => {
  return(
    <div className='m-auto border w-80 my-10'>
      <Calendar onChange={setDateCalendar} value={dateCalendar} onClickDay={(value, event) => changeDate(value, setDateBegining, setDateEnd)} 
        tileClassName={({ date, view }) => {
          if (view === 'month') {
            const isSpecial = isSpecialDate(eventsDate, date) ? 'special-date' : '';
            const isSelected = date.toDateString() === dateCalendar.toDateString() ? 'selected-date' : '';
            return `${isSpecial} ${isSelected}`.trim(); // Ajoute les classes si elles existent
          }
          return null;
        }}
        tileContent={({ date, view }) =>
          view === 'month' && isSpecialDate(eventsDate, date) ? (
            <div style={tileStyles}>{date.getDate()}</div>
          ) : null
        }
      />
    </div>
  ); 
}

export const displayEvents = (user, loader, events, dateBegining, setElementToArchive, displayCoownership=false, setElementToUpdate=false) => {
  return (
    loader ? (
      <div>
        <Loader />
        <p className="font-bold text-center">Le chargement peut durer quelques secondes</p>
      </div>

    ) : (
      events.length > 0 ? (
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={getResponsiveRatio()}
          visibleSlides={1}
          totalSlides={events.length}
        >
          <Slider >
            {events.map((event, key) => {
              return(
                <Slide index={key} key={key}>
                  <div className='border rounded-8 shadow'>
                    <div className='lg:flex'>
                      {displayCoownership && (
                        <div className='flex items-center space-x-2 pl-4'>          
                          <img 
                            src={event.imageCoownership} 
                            alt={event.nameCoownership}
                            className='w-10 h-10 rounded-full object-cover'
                          />
                          <p className='text-sm font-medium'>{event.nameCoownership}</p>
                        </div>
                      )}
                      <h3 className='text-center text-lg p-4 font-bold flex-1'>{event.title}</h3>
                      {isCoownership(user.user) || (isProvider(user.user) && event.idCreator === "users/"+user.user.user.uid) ? (
                        <div className="flex flex-end">
                          <a href={"#"} onClick={() => setElementToArchive(event.coownership+"/events/"+event.id)} className=' p-4'>
                            <p className='text-red-500 font-bold'>Archiver</p>
                          </a>
                          {setElementToUpdate !== false && (
                            <a href={"#"} onClick={() => setElementToUpdate(event.id)} className=' p-4'>
                              <p className='text-blue-500 font-bold'>Modifier</p>
                            </a>
                          )}
                        </div>
                      ) : null }
                    </div>

                    {event?.files && event?.files?.length > 0 && event?.files.map((file, index) => (
                      <div key={index} className="flex items-center justify-between bg-gray-100 pl-4 rounded">
                        {file.typeMedia === "image" ? (
                          <a href={file.url} target='_blank'>
                            <p className='text-blue-600 p-1'>Afficher l'image</p>
                          </a>
                        ) : (
                          <a href={file.url} target='_blank'>
                            <p className='text-blue-600 p-1'>Afficher le pdf</p>
                          </a>
                        )}
                      </div>
                    ))}

                    <p className='text-left px-4 py-2 whitespace-pre-wrap'>Créé par {event.firstname + " " + event.lastname}</p>
                    <p className='text-left px-4 py-2 whitespace-pre-wrap'>{event.description}</p>
                    {event.multipleDates && (
                      <p className='text-primary pl-4'>Événement prévu sur plusieurs dates</p>
                    )}
                    {event.multipleDates ? (
                      <p className='text-left px-4 py-2'>{"Prévu du "+ dateFromTimestampToString(event.dateBegining) +" à " + timeFromTimestampToString(event.dateBegining) + " jusqu'au " + dateFromTimestampToString(event.dateEnd) + " à " + timeFromTimestampToString(event.dateEnd)}</p>
                    ) : (
                      <p className='text-left px-4 py-2'>{"Prévu le "+ dateFromTimestampToString(event.dateBegining) +" de " + timeFromTimestampToString(event.dateBegining) + " à " + timeFromTimestampToString(event.dateEnd)}</p>
                    )}
                    
                    
                  </div>
                
                </Slide>
              )
            })}
          </Slider>
          <Wrapper>
            <div className="controls">
              <ButtonBack className="btn-arrow ">
                <p className='text-cyan-600'>Précédent</p>
              </ButtonBack>
              <DotGroup className="dot-group" />
              <ButtonNext className="btn-arrow">
                <p className='text-cyan-600'>Suivant</p>
              </ButtonNext>
            </div>
          </Wrapper>

        </CarouselProvider>
      ) : (
        dateBegining ? (
          <p className='text-center'>Aucun événement prévu pour le {dateFromTimestampToString(Math.floor(dateBegining.getTime()/1000))}</p>
        ) : null
      )
    )
  );
}

export const archiveEvent = async (eventPath, setShowArchiveModal) => {
  try{
    if(eventPath !== null){
      const docRef = doc(db, eventPath);
      await updateDoc(docRef, {
        status: "archived"
      });
      setShowArchiveModal(false);
    }
  }catch(err){
    console.log("impossible d'archiver cet événement", err);
  }
}


