import { collection, doc, getFirestore, addDoc, updateDoc, Timestamp, query, where, orderBy, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Input from '../components/inputs/Input';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { useUser } from '../contexts/UserContext';
import { dateFromTimestampToHtmlFormat } from '../librairies/Dates';
import { generateRandomCode } from '../librairies/Codes';
import { fetchData, fetchDocs } from '../librairies/Firestore';

const db = getFirestore(app);

function LinkCoownership({onClose}) {

  //contexte de l'utilisateur connecté
  const user = useUser();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  //coownership
  const [coownershipsAccount, setCoownershipsAccount] = useState([]); 
  const [selectedCoownership, setSelectedCoownership] = useState(null);

  //comptes gestionnaires - syndic
  const [existAccount, setExistAccount] = useState(false); 
  const [syndicAccount, setSyndicAccount] = useState([]); 
  const [selectedSyndicAccount, setSelectedSyndicAccount] = useState(null);

  const [emailAccount, setEmailAccount] = useState("");
  const [password, setPassword] = useState(generateRandomCode());

  /*
  const [nameSyndic, setNameSyndic] = useState("");
  const [phoneSyndic, setPhoneSyndic] = useState("");
  const [mailSyndic, setMailSyndic] = useState(""); 
  */

  const [errors, setErrors] = useState({
    emailAccount: ''
  });


  const resetState = () => {
    setError("");
    setEmailAccount(""); 
    setPassword("");
  }

  useEffect(() => {
    console.log("we load syndics accounts and coownerships"); 
    getSyndicAccounts();
    getCoownerships();
  }, []); 


  const updateCoownershipArray = async () => {
    const docRef = doc(db, 'users', selectedSyndicAccount);
    let user = await fetchData(docRef); 

    if(user !== null){

      let coownershipArray = user.coownershipId; 

      let newObj = {coownershipReference: doc(db, 'coownership', selectedCoownership)}; 

      coownershipArray.push(newObj); 

      //on met à jour l'array coownershipId du compte user
      await updateDoc(docRef, {
        coownershipId: coownershipArray
      });

      //on met à jour l'email de la copropriété
      await updateDoc(doc(db, 'coownership', selectedCoownership), {
        email: user.email
      });
    }
  }

  /**
   * récupère l'ensemble des comptes syndics existants
   */
  const getSyndicAccounts = async () => {
    const collectionRef = query(collection(db, "users"), where("status", "==", "copropriete"), orderBy("email"));

    let data = await getDocs(collectionRef); 
    
    if(data !== null){
      setSyndicAccount(data.docs); 
    }
  }

  /**
   * Récupère l'ensemble des copropriétés
   */
  const getCoownerships = async () => {
    const collectionRef = query(collection(db, "coownership"), orderBy("name"));

    let data = await getDocs(collectionRef); 
    
    if(data !== null){
      setCoownershipsAccount(data.docs); 
    }
  }

  /**
   * On submit le formulaire
   * @param {*} e 
   */
  const handleSubmit = async (e)  => {
    e.preventDefault();
    console.log("submit coownership");
    
    try{
      setLoader(true);
      
      console.log(selectedCoownership); 
      console.log(selectedSyndicAccount);

      
      if(selectedCoownership !== null){
        //si le choix a été fait d'attribuer la copro à un compte syndic existant
        if(existAccount){

          if(selectedSyndicAccount !== null){
            //on update l'array de coownership du compte sélectionné
            await updateCoownershipArray(); 
          }else {
            setError("Aucun compte syndic n'a été sélectionné");
            setLoader(false);

            return; 
          }
        }else{ //le choix a été fait de créer un nouveau compte pour le syndic 
          //on ajoute le nouveau document avec un nouveau compte pour le syndic

          const createSyndicCollection = collection(db, "create_syndic");

          await addDoc(createSyndicCollection, {
            emailAccount: emailAccount,
            passwordAccount: password,
            coownershipId: selectedCoownership
          }).then(async (doc) => {
            console.log("le doc created")
            console.log(doc.path)
          }).catch((err) => {
            console.log(err)
            setError("Une erreur est survenue, impossible de créer le nouveau compte syndic");
            setLoader(false);
            return; 
          });

          //le compte du syndic est créé grâce à une fonction (cloud functions)
        }

        setLoader(false);
        //on remet tous les states par défaut
        resetState(); 

        //on close la popup
        onClose(); 
      }else{
        setError("Aucune copropriété n'a été sélectionnée");
        setLoader(false);
        return; 
      }
    } catch(err){
      console.log(err)
      setError("Une erreur est survenue, impossible de lier la copropriété");
      setLoader(false);
    }

  };


  return (
    <div id="authentication-modal" tabindex="-1" aria-hidden="true" className="mx-auto w-full fixed inset-0 flex items-center justify-center overflow-auto">
      <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <button type="button" onClick={onClose} className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">Lier une copropriété</h3>

            <form onSubmit={handleSubmit}>

              <div className='mb-6'>
                <div className='text-lg text-primary'>La copropriété</div>
                <div className='border-b border-gray mt-0.5 mb-2'></div>

                <div>
                  <label for="coownerships" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Selectionnez une copropriété</label>
                  <select id="coownerships" onChange={(e) => setSelectedCoownership(e.target.value)} 
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                    <option selected>Sélectionnez une copropriété</option>
                    {coownershipsAccount.length > 0 ? (
                      coownershipsAccount.map((coownership) => {
                        return (
                          <option key={coownership.id} value={coownership.id}>{coownership.data().name}</option>
                        )
                      })
                    ) : null }
                  </select>
                </div>
              </div>


              <div className='mb-6'>
                <div className='text-lg text-primary'>Les infos du compte</div>
                <div className='text-sm'>informations de compte permettant de se connecter à l'interface web reservée aux copropriétés</div>
                <div className='border-b border-gray mt-0.5 mb-2'></div>


                <div className='flex gap-4 my-4'>
                  <input type="radio" value={false} name="gender" checked={!existAccount} onChange={() => setExistAccount(false)}/> Nouveau compte
                  <input type="radio" value={true} name="gender" checked={existAccount} onChange={() => setExistAccount(true)}/> Compte existant
                </div>

                {existAccount ? (
                  <div>
                    <label for="account" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Selectionnez un compte</label>
                    <select id="account" onChange={(e) => setSelectedSyndicAccount(e.target.value)} 
                     class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option selected>Sélectionnez un compte Syndic</option>
                      {syndicAccount.length > 0 ? (
                        syndicAccount.map((account) => {
                          return (
                            <option key={account.id} value={account.id}>{account.data().email}</option>
                          )
                        })
                      ) : null }
                    </select>
                  </div>
                ) : (
                  <div>
                    <Input id={"emailAccount"} label={"Email"} type={"email"} name={"emailAccount"} value={emailAccount} onChange={(event) => setEmailAccount(event.target.value)} error={errors.emailAccount} />
                    <div className='text-sm text-secondary'>Le compte sera créé avec le mot de passe ci-dessous. Veuillez le copier et le transmettre à la personne qui gèrera la copropriété. Le mot de passe ne sera pas réaffiché</div>

                    <div className='text-lg text-primary font-bold my-2 text-center'>{password}</div>
                  </div>
                )}



              </div>

              <div className="text-center">
                <PrimaryButton type={"submit"} value={"Ajouter"} loading={loader} />
              </div>

              {error !== "" ? (
                <p className='justify-center flex my-1 text-red-500'>{error}</p>
              ) : null}
              
            </form>

          </div>
        </div>
      </div>
    </div> 
  );
}

export default LinkCoownership;