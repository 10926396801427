import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, onSnapshot, orderBy, query } from 'firebase/firestore'; 
import Loader from '../components/Loader';
import { useUser } from '../contexts/UserContext';
import { dateFromTimestampToString } from '../librairies/Dates';
import TextModal from '../components/modals/TextModal';
import {Icon} from 'react-icons-kit';
import {filter} from 'react-icons-kit/feather/filter';
//import {chevronDown} from 'react-icons-kit/feather/chevronDown';
//import {chevronUp} from 'react-icons-kit/feather/chevronUp';


const db = getFirestore(app);

const ListClaims = () => {

  const [claims, setClaims] = useState([]); 
  const [loader, setLoader] = useState(true);

  const [showTextModal, setShowTextModal] = useState(false); 
  const [descriptionToShow, setDescriptionToShow] = useState(null); 
  const [filterDate, setFilterDate] = useState(true); 
  const [filterProvider, setFilterProvider] = useState(false);

  /*
  const [showAddProvider, setShowAddProvider] = useState(false); 
  const [showUpdateProvider, setShowUpdateProvider] = useState(false); 
  const [showDeleteModal, setShowDeleteModal] = useState(false); 
  const [elementToDelete, setElementToDelete] = useState(null); 
  const [elementToUpdate, setElementToUpdate] = useState(null);
  */

  //contexte de l'utilisateur connecté
  const user = useUser();


  useEffect(() => {
    const collectionRef = query(
      collection(db, user.user.userData.lastCoownershipLoaded.path+"/claims"),
      ...(filterDate ? [orderBy("dateCreation", "desc")] : []),
      ...(filterProvider && !filterDate ? [orderBy("nameProvider")] : [])
    );


    const unsubscribe = onSnapshot(collectionRef, (snapshot) => {

      if(snapshot.docs.length > 0){
        
        let arr = [];

        snapshot.docs.map((claim) => {
          let obj = {
            id: claim.id, 
            object: claim.data().object, 
            text: claim.data().text, 
            firstname: claim.data().firstnameCreator,
            lastname: claim.data().lastnameCreator,
            date: claim.data().dateCreation.seconds,
            status: claim.data().status,
            nameProvider: claim.data().nameProvider,
            image: claim.data().image
          }

          arr.push(obj);

        });
        
        setClaims(arr);
      }

      setLoader(false);
    });

    return () => {
      console.log("unsubscribe claims");
      unsubscribe();
    }
  }, [filterDate, filterProvider]); 


  useEffect(() => {
    if(showTextModal === false){
      setDescriptionToShow(null);
    }
  }, [showTextModal]);

  useEffect(() => {
    if(descriptionToShow != null){
      setShowTextModal(true); 
    }
  }, [descriptionToShow]);

  /*
  useEffect(() => {
    if(elementToDelete != null){
      setShowDeleteModal(true); 
    }
  }, [elementToDelete]);

  useEffect(() => {
    if(elementToUpdate != null){
      setShowUpdateProvider(true); 
    }
  }, [elementToUpdate]);


  useEffect(() => {
    if(showDeleteModal === false){
      setElementToDelete(null);
    }
  }, [showDeleteModal]);


  useEffect(() => {
    if(showUpdateProvider === false){
      setElementToUpdate(null);
    }
  }, [showUpdateProvider]);
  */

  /**
   * On supprime un prestataire
   */
  /*
  const archiveClaim = async () => {
    if(elementToDelete !== null){
      const docRef = doc(db, user.user.userData.lastCoownershipLoaded.path+"/providers/"+elementToDelete);
      await deleteDoc(docRef);

      setShowDeleteModal(false);

    }
  }*/


  return (
    <div>
      {/*}
      <a href="#" onClick={() => setShowAddProvider(true)} className="justify-end flex mb-4">
        <PrimaryButton type={"button"} value={"Ajouter un prestataire"} loading={false} />
      </a>*/}
      
      {loader ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                
                <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => setFilterDate(!filterDate)}>
                    Date                 
                    <span className={filterDate ? "text-primary" : "text-black"}>
                      <Icon className="ml-1" icon={filter} size={16} />
                    </span>
                </th>

                <th scope="col" className="px-6 py-3">
                    Copropriétaire
                </th>
                <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => setFilterProvider(!filterProvider)}>
                    Société
                    <span className={filterProvider ? "text-primary" : "text-black"}>
                      <Icon className="ml-1" icon={filter} size={16} />
                    </span>
                </th>
                <th scope="col" className="px-6 py-3">
                    Objet
                </th>
                <th scope="col" className="px-6 py-3">
                    Statut
                </th>
                <th scope="col" className="px-6 py-3">
                    Action
                </th>
              </tr>
            </thead>
            <tbody>
              {claims.map((claim, key) => {
                return (
                  <tr key={key} className={key % 2 === 0 ? "bg-white border-b dark:bg-gray-900 dark:border-gray-700" : "border-b bg-gray-50 dark:bg-gray-800 dark:border-gray-700"}>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {dateFromTimestampToString(claim.date)}
                    </th>
                    <td className="px-6 py-4">
                      {claim.firstname + " " + claim.lastname}
                    </td>
                    <td className="px-6 py-4">
                      {claim.nameProvider}
                    </td>
                    <td className="px-6 py-4">
                      {claim.object}
                    </td>
                    <td className="px-6 py-4">
                      {claim.status === "activated" ? "Créée" : claim.status}
                    </td>
                    <td className="px-6 py-4">
                      <div className='flex gap-4'>
                        <a href="#" onClick={() => setDescriptionToShow(claim.text)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Afficher la description</a>
                        {/*<a href="#" onClick={() => console.log(claim.id)} className="font-medium text-red-600 dark:text-red-500 hover:underline">Archiver</a>*/}
                        {claim.image && (
                          <a href={claim.image} target='_blank' className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Voir l'image</a>
                        )}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      )}

      {showTextModal ? (
        <TextModal onClose={() => setShowTextModal(false)} text={descriptionToShow}/>
      ) : null }

      {/*showAddProvider ? (
        <AddProvider onClose={() => setShowAddProvider(false)}/>
      ) : null }

      {showUpdateProvider ? (
        <AddProvider onClose={() => setShowUpdateProvider(false)} provider={elementToUpdate} />
      ) : null }

      {showDeleteModal ? (
        <DeleteModal closeAction={() => setShowDeleteModal(false)} confirmAction={() => deleteProvider()}/>
      ) : null */}
      
    </div>
  );
};

export default ListClaims;
