import React from 'react';

function FilesInput({id, name, label, files, onChange, onRemoveFile, error=null, fileAccepted="image/*"}) {

  return (
    <div className="mb-2">
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mx-auto mt-0.5 p-4 bg-white rounded shadow">
        <input
          id={id}
          name={name}
          type="file"
          accept={fileAccepted}
          onChange={onChange}
          className="mb-4"
          multiple={true}
        />
        {files?.length > 0 && files.map((file, index) => (
          <div key={index} className="flex items-center justify-between bg-gray-100 p-2 rounded mb-2">
            <p className="text-sm">{file.name}</p>
            <div
              onClick={() => onRemoveFile(index)}
              className="ml-2 text-red-500 hover:text-red-700 text-lg"
            >
              ❌
            </div>
          </div>
        ))}
      </div>

      {error && (
        <p className="text-red-500 text-sm mt-1">{error}</p>
      )}
    </div>
  );
}

export default FilesInput;