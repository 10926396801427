import React, { useState, useEffect } from 'react';
import { app } from '../firebase'; // Importez votre configuration Firebase
import { getFirestore, collection, getDocs, query, where, getDoc, doc } from 'firebase/firestore'; 
import { useUser } from '../contexts/UserContext';
import DeleteModal from '../components/modals/DeleteModal';
import AddEvent from '../events/AddEvent';
import moment from 'moment';
import { addEventButton, archiveEvent, displayCalendar, displayEvents } from './CalendarFunctions';

const db = getFirestore(app);

const CustomCalendar = ({listCoownership}) => {

  const [events, setEvents] = useState([]); 
  const [loader, setLoader] = useState(true);
  const [showAddEvent, setShowAddEvent] = useState(false); 
  const [showArchiveModal, setShowArchiveModal] = useState(false); 
  const [elementToArchive, setElementToArchive] = useState(null); 
  const [dateCalendar, setDateCalendar] = useState(new Date()); 
  const [dateBegining, setDateBegining] = useState(new Date()); 
  const [dateEnd, setDateEnd] = useState(() => {
    const nextDay = new Date(dateBegining); // Clone `dateBegining`
    nextDay.setDate(nextDay.getDate() + 1); // Incrémente le jour de 1
    return nextDay;
  });

  const [allEvents, setAllEvents] = useState([]); // [{ "YYYY-MM-DD": [events] }]
  const [eventsDate, setEventsDate] = useState([]);

  //contexte de l'utilisateur connecté
  const user = useUser();  

  // On charge l'ensemble des événements sur le calendrier commun 
  useEffect(() => {
    const fetchAllEvents = async () => {

      setEventsDate([]); //on remet les dates à vide pour insérer toutes les dates

      if (!user?.user?.userData?.coownershipId?.length) {
        setLoader(false);
        return;
      }

      try{
        setLoader(true);

        // Objets temporaires pour stocker les événements
        const tempEventsMap = [];
        const allEventDates = []; // Tableau temporaire pour collecter toutes les dates

        for(let i=0; i< user.user.userData.coownershipId.length; ++i){

          const coownershipPath = user.user.userData.coownershipId[i].coownershipReference.path;
          
          const queryPlanning = query(collection(db, coownershipPath+"/events"), where('status', '==', 'accepted')); 
          
          const events = await getDocs(queryPlanning); 
          if(events.docs.length > 0){
            
            // Parcourir tous les événements
            events.docs.forEach((doc) => {
              const event = doc.data();
              const eventId = doc.id;
              // Convertir le timestamp en millisecondes (Firestore utilise des millisecondes)
              const eventDate = new Date(event.dateBegining.seconds * 1000);
              const dateKey = moment(eventDate).format('YYYY-MM-DD');
              
              // Ajouter la date à notre tableau temporaire
              if (!allEventDates.includes(dateKey)) {
                allEventDates.push(dateKey);
              }

              // Créer un objet d'événement
              const eventObj = {
                coownership: coownershipPath,
                id: eventId,
                idCreator: event.idCreator.path,
                title: event.title,
                description: event.description,
                firstname: event.firstnameCreator,
                lastname: event.lastnameCreator,
                dateBegining: event.dateBegining.seconds,
                dateEnd: event.dateEnd.seconds,
                status: event.status,
                files: event.files,
                multipleDates: event?.multipleDates ?? false, 
              };

              // Ajouter à la map d'événements par date
              if (!tempEventsMap[dateKey]) {
                tempEventsMap[dateKey] = [];
              }
              
              tempEventsMap[dateKey].push(eventObj);
            
            }); 
          }
        }

        setAllEvents(tempEventsMap); // Mettre à jour les états avec toutes les données
        setEventsDate(allEventDates); // Mise à jour unique de eventsDate
        
      } catch (error) {
        console.error("Erreur lors du chargement des événements:", error);
      } finally {
        setLoader(false);
      }
    }

    fetchAllEvents();
  }, []); 


  // Mettre à jour les événements actuels lorsque la date change
  useEffect(() => {

    const setEventsFromDate = async () => {
      const dateKey = moment(dateBegining).format('YYYY-MM-DD');
    
      if (allEvents[dateKey]) {
  
        // Créer une copie des événements pour pouvoir les modifier
        const updatedEvents = [...allEvents[dateKey]];
        
        // Pour chaque événement, récupérer les informations de la copropriété
        for (let i = 0; i < updatedEvents.length; ++i) {
          try {
            // Récupérer le document de la copropriété
            const coownershipRef = doc(db, updatedEvents[i].coownership);
            const coownershipSnap = await getDoc(coownershipRef);
            
            if (coownershipSnap.exists()) {
              const coownershipData = coownershipSnap.data();
              
              // Ajouter les champs image et name de la copropriété à l'événement
              updatedEvents[i] = {
                ...updatedEvents[i],
                imageCoownership: coownershipData.image || '',
                nameCoownership: coownershipData.name || ''
              };
            }
          } catch (error) {
            console.error('Erreur lors de la récupération de la copropriété:', error);
          }
        }
  
        setEvents(updatedEvents);
      } else {
        setEvents([]);
      }
    }
    
    setEventsFromDate(); 
  }, [dateBegining, allEvents]);


  useEffect(() => {
    if(elementToArchive !== null){
      setShowArchiveModal(true); 
    }
  }, [elementToArchive]);

  useEffect(() => {
    if(showArchiveModal === false){
      setElementToArchive(null);
    }
  }, [showArchiveModal]);


  return (
    <div>  
      <div className='flex gap-4 justify-end'>
        {addEventButton(dateBegining, setShowAddEvent)} 
      </div>
      
      {displayCalendar(dateCalendar, setDateCalendar, setDateBegining, setDateEnd, eventsDate)}
      {displayEvents(user, loader, events, dateBegining, setElementToArchive, true)}

      {showAddEvent ? (
        <AddEvent onClose={() => setShowAddEvent(false)} date={dateBegining} listCoownership={listCoownership}/>
      ) : null }

      {/* todo : il n'y a pas de reload après l'archive
      showArchiveModal ? (
        <DeleteModal closeAction={() => setShowArchiveModal(false)} confirmAction={() => archiveEvent(elementToArchive, setShowArchiveModal)} type={"archive"}/>
      ) : null */ }
    </div>
  );
};


export default CustomCalendar;

